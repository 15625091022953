export function faq(){
	$(".FAQ .faqList li dt").on("click",function(){
		$(this).next().stop().slideToggle(200);
		if ($(this).is('.addClose')) {
			$(this).removeClass('addClose');
		} else {
			$(this).addClass('addClose');
		}
	});
}
