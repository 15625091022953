export function commons(){


    //バーガーメニュー
    $("#HEADER .gnavi").on("click", function () {
        if ($(window).width() < 769) {
            $(this).children("ul").stop().slideToggle(200);
            if ($(this).is('.addClose')) {
                $(this).removeClass('addClose');
            } else {
                $(this).addClass('addClose');
            }
        }
    });

    // Aタグクリック時のスクロール
	$('a[href^="#"]').on("click",function() {
		let href= $(this).attr("href");
		let target = $(href == "#" || href == "" ? 'html' : href);
		let position = target.offset().top - $("#HEADER").height();
		$('html,body').animate({scrollTop:position},600);
		return false;
	});

    const controller = new ScrollMagic.Controller();
	//アニメーション
	$(".motion-animate").each(function(){
		let s = $(this).offset().top - 300;
		let d = $(this);
		new ScrollMagic.Scene({
			triggerElement:'body',
			offset: s
		})
		.on('start', function(){
			d.addClass("active");
		})
		//.addIndicators()
		.addTo(controller);

	});

	new ScrollMagic.Scene({
		triggerElement:'body',
		offset: $(window).height()
	}).setClassToggle('#pagetop','active')
	//.addIndicators()
	.addTo(controller);

	new ScrollMagic.Scene({
		triggerElement:'body',
		offset: 400
	}).setClassToggle('#HEADER','active')
	//.addIndicators()
	.addTo(controller);

}
