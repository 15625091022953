import {commons} from './common';
import {index} from './index';
import {vues} from './vues';
import {faq} from './faq';
import {modaal} from './modaal';

$(() => {
    vues();
    commons();
    index();
	faq();
    modaal();
});
