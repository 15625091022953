export function vues(){
    const sidebar = Vue.component('sidebar',{
        template:`
            <ul>
				<li><a href="/topmessage/">トップメッセージ</a></li>
                <li><a href="/company/">会社概要</a></li>
                <li><a href="/history/">会社沿革</a></li>
                <li><a href="/iso/">ISO9001</a></li>
                <li><a href="/next/">次世代法</a></li>
            </ul>
        `
    });

    new Vue({
        el: "#WRAPPER",
        data: {
            departList:[],
            historyList:[]
        },
        created() {
            // エリア情報を取得
            axios.get('/assets/department.json')
            .then( response => {
                this.departList = response.data;
            })
            .catch(error => {
                console.log('error');
            });

            //沿革情報を取得
            axios.get('/assets/history.json')
            .then( response => {
                this.historyList = response.data;
            })
            .catch(error => {
                console.log('error');
            });
        },
        methods: {
            historyOpen: function(ary){
                console.log(ary);
                let text = "";
                ary.forEach(function(elem){
                    text += elem;
                    text += "<br>";
                });
                return text;
            }
        }
    });
	
	
	
	
    new Vue({
        el: "#HEADER",
		data: {
			language : '',
			search : false,
		},
		methods: {
			links: function(){
				let lang = this.language;
				if(lang != ""){
					location.href = lang;
				}
			},
			search_toggle : function(){
				return this.search = !this.search;
			}
		}
	});
}
