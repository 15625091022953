export function modaal(){
	$(".modaal").modaal({
        type:'image',
        hide_close: false,
    });
    
    $(".modaal_video").modaal({
        type:'video',
        hide_close: false,
    });
}
