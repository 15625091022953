export function index(){

	const tag = document.getElementById('firstview');
	if(tag != null){
		const parse = $("#firstview .parse");
		const dtelem = $("#firstview .sha");
		const finish = parse.attr("data-finish");
		const timer = 16;
		let num = 0;

		setTimeout(function(){
			parse.addClass("active");
		},500);

		setTimeout(function(){
			const countdown = setInterval(function(){
				parse.text( getdoubleDigestNumer(num) );
				num ++;
				if(num > finish){
					clearInterval(countdown);
					parse.addClass("animate");
					dtelem.addClass("animate");
				}
			},timer);
		},800);

		function getdoubleDigestNumer(number) {
		  return ("0" + number).slice(-2)
		}
	}
}
